<script setup>
// Data theme should always be 'light' because stripe checkout cannot currently support multiple themes
useHead({
  htmlAttrs: {
    'data-theme': 'light',
    'data-can-switch-theme': false,
  },
});
</script>

<template>
  <main
    id="root"
    :class="$style.container"
  >
    <div
      class="brand-color tw-py-4 tw-text-center"
      :class="$style.siteHeader"
    >
      <NuxtLink to="/">
        <img
          alt="OpenSnow logo"
          class="tw-w-40 md:tw-w-48 tw-inline-block"
          src="~/assets/img/OpenSnowLogo.png"
        >
      </NuxtLink>
    </div>
    <div :class="$style.siteContent">
      <slot />
    </div>
  </main>
</template>

<style module>
.container {
  display: grid;
  font-size: 1rem;
  grid-template:
  "siteHeader" auto
  "siteContent" minmax(60vh, 1fr);
  line-height: 1.5rem;
  min-height: 100vh;
}

.siteHeader {
  grid-area: siteHeader;
}

.siteContent {
  grid-area: siteContent;
  width: 100vw;
}

@media (width >= 768px) {
  .siteContent {
    width: auto;
  }
}
</style>
